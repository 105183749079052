import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["suiteNumber", "firstName", "lastName"]
  static values = { 
    url: { type: String, default: "/admin/find_recipient" }
  }

  findUser() {
    if (this.suiteNumberTarget.value == "") {
      this.reset()
      return
    }

    fetch(this.urlValue, {
      method: "post",
      body: JSON.stringify({ "id": parseInt(this.suiteNumberTarget.value) }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content")
      }
    }).then(res => {
      if (!res.ok) { throw new Error() }
      return res.json()
    }).then(recipient => {
      this.firstNameTarget.value = recipient["first_name"]
      this.lastNameTarget.value = recipient["last_name"]
    }).catch((_err) => {
      this.reset()
    })
  }

  reset() {
    this.firstNameTarget.value = ""
    this.lastNameTarget.value = ""
  }
}
