import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "icon"]
  static values = { open: { type: Boolean, default: false }}

  toggle() {
    this.openValue = !this.openValue
    this.contentTarget.toggleAttribute("hidden")

    this.iconTarget.classList.toggle("transform", this.openValue)
    this.iconTarget.classList.toggle("rotate-180", this.openValue)
  }
}
