import { Controller } from "@hotwired/stimulus"
const ARROW_UP_KEY    = "ArrowUp"
const ARROW_DOWN_KEY  = "ArrowDown"
const ENTER_KEY       = "Enter"
const ESCAPE_KEY      = "Escape"
const NAVIGATION_KEYS = [ARROW_UP_KEY, ARROW_DOWN_KEY, ENTER_KEY, ESCAPE_KEY]

export default class extends Controller {
  static targets = ["input", "list", "listItem"]
  static values = {
    currentIndex: { type: Number, default: -1 },
    url: String,
    eventIsMouseDown: { type: Boolean, default: false }
  }

  connect() {
    // Disable browser autocomplete & spellchecking
    this.inputTarget.setAttribute("autocomplete", "off")
    this.inputTarget.setAttribute("spellcheck", "false")
  }

  onInput(_event) {
    if (this.inputTarget.value == "") {
      this.reset()
      return
    }

    this.buildQueryURL()
    this.fetchAndDisplayResults()
  }

  onBlur(_event) {
    if (this.eventIsMouseDownValue) {
      this.eventIsMouseDownValue = false
      return
    }
    this.reset()
  }

  onMouseDown(_event) {
    this.eventIsMouseDownValue = true
  }

  onClick(event) {
    if (this.element.contains(event.target)) {
      this.inputTarget.value = event.target.textContent.trim()
    }
    this.inputTarget.focus()
    this.reset()
  }

  onKeyDown(event) {
    // Return on non navigation keys
    if (!NAVIGATION_KEYS.includes(event.key)) {
      return
    }

    event.preventDefault()

    // We have some results from server
    if (this.listItemTargets.length > 0) {
      switch(event.key) {
      case ARROW_UP_KEY:
        this.selectPrevListItem()
        this.highlightListItem()
        break
      case ARROW_DOWN_KEY:
        this.selectNextListItem()
        this.highlightListItem()
        break
      case ENTER_KEY:
        this.setInputValue()
        break
      case ESCAPE_KEY:
        event.stopPropagation()
        this.reset()
        break
      }
    }
  }

  reset() {
    this.currentIndexValue = -1
    this.listTarget.innerHTML = ""
  }

  buildQueryURL() {
    let url = new URL(this.urlValue)
    url.searchParams.append("q", this.inputTarget.value.trim())
    this.urlValue = url
  }

  fetchAndDisplayResults() {
    fetch(this.urlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content")
      }
    })
    .then(res => res.text())
    .then(data => this.listTarget.innerHTML = data)
    .catch((_err) => {})
  }

  selectPrevListItem() {
    if (this.currentIndexValue == -1) {
      this.currentIndexValue = 0
    } else if (this.currentIndexValue > 0) {
      this.currentIndexValue--
    }
  }

  selectNextListItem() {
    if (this.currentIndexValue < this.listItemTargets.length - 1) {
      this.currentIndexValue++
    } else {
      this.currentIndexValue = 0
    }
  }

  setInputValue() {
    this.listItemTargets.forEach((element, index) => {
      if (index == this.currentIndexValue) {
        this.inputTarget.value = element.textContent.trim()
      }
    })
    this.reset()
  }

  highlightListItem() {
    this.listItemTargets.forEach((element, index) => {
      element.classList.toggle("text-gray-900", index == this.currentIndexValue)
    })
  }
}
