import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['iframe']

  openPrintDialog(e) {
    e.preventDefault()

    this.iframeTarget.focus()
    this.iframeTarget.contentWindow.print()
  }
}
