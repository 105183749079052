import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source", "helperIconText"]

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.textContent.trim())
    this.helperIconTextTarget.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-500 fill-current" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
      <div class="hidden md:block text-green-500">Есть</div>
    `

    setTimeout(() => {
      this.showHelperText()
    }, 1000)
  }

  showHelperText() {
    this.helperIconTextTarget.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500 fill-current" viewBox="0 0 512 512"><rect x="128" y="128" width="336" height="336" rx="57" ry="57" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/><path d="M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
      <div class="hidden md:block group-hover:text-gray-500">Скопировать</div>
    `
  }
}
