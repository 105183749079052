import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "menu"]
  static values = { open: Boolean }

  connect() {
    this.openValue = false
    document.addEventListener("keydown", this._onMenuButtonKeydown)
  }

  disconnect() {
    document.removeEventListener("keydown", this._onMenuButtonKeydown)
  }

  openValueChanged() {
    this.menuTarget.toggleAttribute("hidden")
  }

  toggle() {
    this.openValue = !this.openValue
  }
  
  hide(event) {
    if (!this.element.contains(event.target) && this.openValue) {
      this.openValue = false
    }
  }

  _onMenuButtonKeydown = event => {
    switch (event.keyCode) {
      case 27: // esc
        event.preventDefault()
        this.openValue = false
    }
  }
}
