import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import AwsS3 from "@uppy/aws-s3"
import Russian from "@uppy/locales/lib/ru_RU"

export default function uppyUploader() {
  const uppy = new Uppy({
    locale: Russian,
    restrictions: {
      minFileSize: 51200, // 50kb
      maxFileSize: 10485760, // 10Mb
      maxNumberOfFiles: 2,
      allowedFileTypes: ["image/jpeg", "image/png"]
    }
  }).use(Dashboard, {
    trigger: ".uppy-open-modal",
  }).use(AwsS3, {
    companionUrl: "/"
  })

  uppy.on("upload-success", (file, _response) => {
    // construct uploaded file data in the format that Shrine expects
    const uploadedFileData = {
      id: file.meta["key"].match(/^cache\/(.+)/)[1], // object key without prefix
      storage: "cache",
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      }
    }

    // append filedata with hidden fields to form
    const hiddenField = document.createElement("input")
    hiddenField.type = "hidden"
    hiddenField.name = `recipient[passport_scans_attributes][${new Date().getTime()}][image]`
    hiddenField.value = JSON.stringify(uploadedFileData)
    document.querySelector(".uppy-direct-upload-hidden-fields").appendChild(hiddenField)
  })
}
