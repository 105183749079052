// Example usage:
// form.html.erb:
//   <%= form_with ... data: { controller: "nested-form" } do |f| %>
//     <template data-nested-form-target="template">
//       <%= f.fields_for NESTED_ASSOCIATION_NAME, NestedAssociationName.new, child_index: "NEW_RECORD" do |ff| %>
//         <%= render "nested_association_form", f: ff %>
//       <% end %>
//     </template>
//   
//     <%= f.fields_for NESTED_ASSOCIATION_NAME do |ff| %>
//       <%= render "nested_association_form", f: ff %>
//     <% end %>
//   
//     <a href="#" type="button" data-action="nested-form#add">
//       Add
//     </a>
//   <% end %>
//
// _nested_association_form.html.erb
//   <div class="nested-form-wrapper" data-new-record="<%= f.object.new_record? %>">
//     ...
//
//     <button type="button" data-action="nested-form#remove">
//       Remove
//     </button>
//   
//     <%= f.hidden_field :_destroy %>
//   </div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template"]
  static values = {
    wrapperSelector: { type: String, default: ".nested-form-wrapper" }
  }

  connect() {
    // Hide flagged for deletion records on connect if we have validation errors
    let hiddenInputsMarkedForDelete =
      document.querySelectorAll(`${this.wrapperSelectorValue} input[name*="_destroy"][value="true"]`)
    for (let hiddenInput of hiddenInputsMarkedForDelete) {
      let element = hiddenInput.closest(this.wrapperSelectorValue)
      element.style.display = "none"
    }
  }

  add(event) {
    event.preventDefault()

    let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime()).toString()
    this.templateTarget.insertAdjacentHTML("afterend", content)
  }

  remove(event) {
    event.preventDefault()

    let wrapper = event.target.closest(this.wrapperSelectorValue)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove()
    } else { // Existing records are hidden and flagged for deletion
      wrapper.style.display = "none"
      wrapper.querySelector("input[name*='_destroy']").value = "true"
    }
  }
}
