import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-menu-toggle"
export default class extends Controller {
  static targets = ["button-open", "button-close", "menu"]
  static values = { open: Boolean }

  connect() {
    this.openValue = false
  }

  openValueChanged() {
    this.menuTarget.toggleAttribute("hidden")
  }

  open() {
    this.openValue = true
  }

  close() {
    this.openValue = false
  } 
}
